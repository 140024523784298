import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "./layouts/Layout";
import { PromptListPage } from "./pages/PromptListPage";
import { PromptNewPage } from "./pages/PromptNewPage";
import { PromptEditPage } from "./pages/PromptEditPage";
import { HomePage } from "./pages/HomePage";
import { LinebotListPage } from "./pages/LinebotListPage";
import { LinebotNewPage } from "./pages/LinebotNewPage";
import { LinebotEditPage } from "./pages/LinebotEditPage";

import { RagListPage } from "./pages/RagListPage";
import { RagNewPage } from "./pages/RagNewPage";
import { RagEditPage } from "./pages/RagEditPage";
import { SettingPlanPage } from "./pages/SettingPlanPage";

import ForgotPassword from './pages/ForgotPassword';
import {Login} from './pages/Login';
import Register from './pages/Register';
import Unsubscribe from './pages/Unsubscribe';
import {PrivacyPolicyPage} from './pages/PrivacyPolicy';
import {TermOfServicePage} from './pages/TermOfService';
export const AppRoutes: FC = () => {



  return (
 
      <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/prompt/new" element={<PromptNewPage />} />
        <Route path="/prompt/edit/:id" element={<PromptEditPage />} />
        <Route path="/linebots" element={<LinebotListPage />} />
        <Route path="/linebot/new" element={<LinebotNewPage />} />
        <Route path="/linebot/edit/:id" element={<LinebotEditPage />} />

        <Route path="/prompts" element={<PromptListPage />} />
        <Route path="/rag/new" element={<RagNewPage />} />
        <Route path="/rag/edit/:id" element={<RagEditPage />} />
        <Route path="/rags" element={<RagListPage />} />

        <Route path="/settings/plan" element={<SettingPlanPage />} />
        <Route path="/settings/login" element={<Login />} />
        <Route path="/settings/register" element={<Register />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/settings/unsubscribe" element={<Unsubscribe />} />
        <Route path="/rule/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/rule/term-of-service" element={<TermOfServicePage />} />
      </Route>
    </Routes> 

  );
};
