import { FC, useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

if (!ENCRYPTION_KEY) {
  console.error("暗号化キーが設定されていません。REACT_APP_ENCRYPTION_KEY を確認してください。");
}

type Form = {
  name: string;
  ragId: string;
  promptId: string;
  secretId: string;
  accessKey: string;
  isOpen: boolean;
};

export const LinebotNewPage: FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { userId } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [rags, setRags] = useState<any[]>([]);
  const [prompts, setPrompts] = useState<any[]>([]);

  const encrypt = (text: string): string => {
    if (!ENCRYPTION_KEY) {
      throw new Error("暗号化キーが設定されていません。");
    }
    return CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
  };

  useEffect(() => {
    if (!userId) {
      setErrorMessage("ユーザーIDが取得できませんでした。再ログインしてください。");
      return;
    }

    const fetchData = async () => {
      try {
        const ragsRef = collection(db, "rags");
        const promptsRef = collection(db, "prompts");

        const userRagQuery = query(ragsRef, where("userId", "==", userId));
        const userPromptQuery = query(promptsRef, where("userId", "==", userId), where("type", "==", "chat"));

        const openRagQuery = query(ragsRef, where("isOpen", "==", true), where("type", "==", "chat"));
        const openPromptQuery = query(promptsRef, where("isOpen", "==", true), where("type", "==", "chat"));

        const [userRagSnap, userPromptSnap, openRagSnap, openPromptSnap] = await Promise.all([
          getDocs(userRagQuery),
          getDocs(userPromptQuery),
          getDocs(openRagQuery),
          getDocs(openPromptQuery),
        ]);

        const fetchedRags = [
          ...userRagSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...openRagSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ];
        const fetchedPrompts = [
          ...userPromptSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...openPromptSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ];

        setRags(fetchedRags);
        setPrompts(fetchedPrompts);
      } catch (error) {
        console.error("データの取得に失敗しました", error);
        setErrorMessage("データの取得中にエラーが発生しました。再試行してください。");
      }
    };

    fetchData();
  }, [userId]);

  const submit = async (data: Form) => {
    if (isSubmitting) return;

    if (!userId) {
      setErrorMessage("ユーザーIDが取得できません。再ログインしてください。");
      return;
    }

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      if (!data.secretId || !data.accessKey) {
        throw new Error("シークレットIDまたはアクセスキーが入力されていません。");
      }

      const encryptedSecretId = encrypt(data.secretId);
      const encryptedAccessKey = encrypt(data.accessKey);

      const linebotCollectionRef = collection(db, "linebots");
      const docRef = await addDoc(linebotCollectionRef, {
        name: data.name,
        promptId: data.promptId,
        secretId: encryptedSecretId,
        accessKey: encryptedAccessKey,
        isOpen: data.isOpen,
        userId: userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      const webhookURL = `https://linebot.zero-one.llc/lineWebhook?id=${docRef.id}`;
      setSuccessMessage(`${webhookURL}`);
    } catch (error: any) {
      console.error("Line botの登録に失敗しました", error.message);
      setErrorMessage(error.message || "Line botの登録中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{
        p: 5,
        mt: 4,
        mx: "auto",
        maxWidth: "600px",
        border: "1px dashed grey",
        borderRadius: 2,
        backgroundColor: "#f7f7f7",
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5" textAlign="center">
          新しいAI Line Botを登録
        </Typography>

        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Line bot名は必須です" }}
          render={({ field }) => (
            <TextField {...field} label="Line bot名" fullWidth error={!!errors.name} helperText={errors.name?.message} />
          )}
        />

        <Controller
          name="promptId"
          control={control}
          defaultValue=""
          rules={{ required: "Promptの選択は必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="関連付けるPromptを選択"
              fullWidth
              error={!!errors.promptId}
              helperText={errors.promptId?.message}
            >
              {prompts.map((prompt) => (
                <MenuItem key={prompt.id} value={prompt.id}>
                  {prompt.name || "名前未設定"}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          name="secretId"
          control={control}
          defaultValue=""
          rules={{ required: "シークレットIDは必須です" }}
          render={({ field }) => (
            <TextField {...field} label="シークレットID" fullWidth error={!!errors.secretId} helperText={errors.secretId?.message} />
          )}
        />

        <Controller
          name="accessKey"
          control={control}
          defaultValue=""
          rules={{ required: "アクセスキーは必須です" }}
          render={({ field }) => (
            <TextField {...field} label="アクセスキー" fullWidth error={!!errors.accessKey} helperText={errors.accessKey?.message} />
          )}
        />

        <Controller
          name="isOpen"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel control={<Switch {...field} checked={field.value} />} label="公開設定（ON: 一般公開）" />
          )}
        />

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage && (
          <Alert severity="success">
            {successMessage}
            <TextField value={successMessage} fullWidth margin="dense" InputProps={{ readOnly: true }} />
          </Alert>
        )}

        <Button type="submit" variant="contained" fullWidth disabled={isSubmitting || successMessage !== null}>
          {isSubmitting ? <CircularProgress size={24} /> : "登録する"}
        </Button>
      </Stack>
    </Box>
  );
};
