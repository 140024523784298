import { Box, Typography, Button, Grid, Container } from "@mui/material";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

export const HomePage: FC = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ pt: 6, pb: 6 }}>
      {/* サービスの強みをアピール */}
      <Typography variant="h4" align="center" gutterBottom>
        カスタマイズ可能なLINE AIボットを<br />
        ノーコードで簡単に作成！
      </Typography>g
      <Typography variant="body1" align="center" paragraph>
        ココナラで3000〜3万円相当のLINE AIボットを、<strong>10分以内に無料</strong>で作成可能。
        さらに、<strong>無数のボットを作成</strong>して、あなたのビジネスを強化できます！
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        例えば、FAQ対応、営業支援、カスタマーサポートなど、<strong>役割（プロンプト）</strong>と<strong>知識（製品情報やFAQ）</strong>を持たせて、
        無限に働いてもらうことが可能です。設定はリアルタイムで変更できます。
      </Typography>
      <Typography variant="body2" align="center" paragraph>
        カスタマイズのご要望があれば、お気軽にご連絡ください！
      </Typography>

      {/* 作り方の説明 */}
      <Typography variant="h5" align="center" gutterBottom sx={{ mt: 4 }}>
        ボット作成は簡単5ステップ！
      </Typography>

      {/* 作り方のステップを画像やテキストで表示 */}
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Box sx={{ p: 3, border: "2px solid #2196f3", borderRadius: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              1. LINE公式アカウントで情報を取得
            </Typography>
            <Typography variant="body2" gutterBottom>
              LINE DevelopersからチャネルIDやチャネルシークレットを取得。
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/guide/line-settings"
            >
              詳細を見る
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 3, border: "2px solid #4caf50", borderRadius: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              2. プロンプトを設定
            </Typography>
            <Typography variant="body2" gutterBottom>
              AIボットの役割を設定（例: 商品案内、FAQ対応）。
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/guide/prompt-settings"
            >
              詳細を見る
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 3, border: "2px solid #ff9800", borderRadius: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              3. 知識（RAGデータ）を登録
            </Typography>
            <Typography variant="body2" gutterBottom>
              FAQや商品情報など、ボットの知識を登録。
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/guide/rag-settings"
            >
              詳細を見る
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 3, border: "2px solid #f44336", borderRadius: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              4. LINE Botを作成
            </Typography>
            <Typography variant="body2" gutterBottom>
              必要な情報を入力して、AIボットを作成。
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/guide/bot-settings"
            >
              詳細を見る
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ p: 3, border: "2px solid #673ab7", borderRadius: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              5. テストして公開
            </Typography>
            <Typography variant="body2" gutterBottom>
              動作確認を行い、LINE Botを公開！
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={RouterLink}
              to="/guide/test"
            >
              詳細を見る
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* アクションボタン */}
      <Box textAlign="center" sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          component={RouterLink}
          to="/linebot/new"
        >
          今すぐLINE Botを作成する
        </Button>
      </Box>
    </Container>
  );
};

export default HomePage;
