import { FC, useState, useEffect } from "react";
import { Box, Button, Typography, Stack, TextField, CircularProgress, Alert } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

type Form = {
  name: string;
  content: string;
  // tags: string;
  // category: string;
};

export const RagEditPage: FC = () => {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { userId } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchRag = async () => {
      if (!id) return;
      try {
        const ragRef = doc(db, "rags", id);
        const ragSnap = await getDoc(ragRef);

        if (ragSnap.exists()) {
          const ragData = ragSnap.data();
          setValue("name", ragData.name);
          setValue("content", ragData.content);
          // setValue("tags", ragData.tags.join(","));
          // setValue("category", ragData.category);
        } else {
          setErrorMessage("指定されたRAGが見つかりませんでした。");
        }
      } catch (error) {
        console.error("RAGの取得に失敗しました", error);
        setErrorMessage("RAGの読み込み中にエラーが発生しました。再試行してください。");
      } finally {
        setLoading(false);
      }
    };

    fetchRag();
  }, [id, setValue]);

  const submit = async (data: Form) => {
    if (isSubmitting || !id) return; // 二重送信防止とIDがない場合

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // FirebaseのRAGを更新
      const ragRef = doc(db, "rags", id);
      await updateDoc(ragRef, {
        name: data.name,
        content: data.content,
        // tags: data.tags.split(",").map(tag => tag.trim()),
        // category: data.category,
        updatedAt: serverTimestamp(),
      });
      navigate("/rags"); // 更新後に一覧ページへ移動
    } catch (error) {
      console.error("RAGの更新に失敗しました", error);
      setErrorMessage("RAGの更新中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{ p: 5, mt: 4, mx: "auto", maxWidth: "500px", border: '1px dashed grey', borderRadius: 2, backgroundColor: '#f7f7f7' }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={3}>
          <Typography variant="h5" textAlign="center">
            {t('RAGを編集')}
          </Typography>

          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: 'RAG名は必須です' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="RAG名"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />

          <Controller
            name="content"
            control={control}
            defaultValue=""
            rules={{
              required: 'コンテンツは必須です',
              maxLength: { value: 2000, message: 'コンテンツは2000文字以内です' }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="コンテンツ"
                fullWidth
                multiline
                rows={8}
                error={!!errors.content}
                helperText={errors.content?.message}
              />
            )}
          />

          {/* <Controller
            name="tags"
            control={control}
            defaultValue=""
            // rules={{ required: 'タグは必須です' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="タグ (カンマ区切り)"
                fullWidth
                error={!!errors.tags}
                helperText={errors.tags?.message}
              />
            )}
          /> */}

          {/* <Controller
            name="category"
            control={control}
            defaultValue=""
            // rules={{ required: 'カテゴリーは必須です' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="カテゴリー"
                fullWidth
                error={!!errors.category}
                helperText={errors.category?.message}
              />
            )}
          /> */}

          {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
          )}

          <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : t('更新する')}
          </Button>
        </Stack>
      )}
    </Box>
  );
};
