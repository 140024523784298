/* eslint-disable */
import { FC, useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar as MuiToolbar,
  IconButton,
  Typography,
  Theme,
  useMediaQuery,
  Button,
  Tooltip,
  Link
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu as MenuIcon } from "@mui/icons-material";
import CreateIcon from '@mui/icons-material/Create';
import { NavigationDrawer } from "../components/NavigationDrawer";
import { AppNavigationList } from "../components/AppNavigationList";
import Stack from '@mui/material/Stack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useAppContext } from "../context/AppContext";
import { auth, db } from "../config/firebase";
import { SubmitHandler } from "react-hook-form";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import AiSelector from '../components/AiSelector'; 
import LanguageSwitcher from '../components/LanguageSwitcher';
import PersonIcon from '@mui/icons-material/Person';
const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: theme.spacing(0, 2.5),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2.5)
  }
}));
type Inputs = {
  email: string;
  password: string;
};
export const Layout: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, userId,values } = useAppContext();
  const { articleCount } = values;
  const isPc = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const [selectedAI, setSelectedAI] = useState<string>('gpt-3.5-turbo'); // AI選択用の状態

  // 現在のパスに基づいてタイトルを設定
  const getTitle = () => {
    if (pathname.includes("/trends")) return "RAG BuzzAI";
    if (pathname.includes("/article")) return "RAG Writer";
    if (pathname.includes("/images")) return "RAG ImageAPI";
    if (pathname.includes("/settings")) return "Setting";
    if (pathname.includes("/ragmaker")) return "RAG Maker";
    // 他のパスに基づくタイトルを設定...
    return "AI Line Bot"; // デフォルトのタイトル
  };
  const handleLogout = () => {
    auth.signOut();
  };
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCrendential) => {
        navigate("/");
      })

      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert("no user exist.");
        } else {
          alert(error.message);
        }
      });
  };
  // URLが変わった時に、SPの場合は閉じる
  const { pathname } = useLocation();
  useEffect(
    () => {
      if (!isPc && isOpen) {
        setIsOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      {/* アプリケーションバー */}
      <AppBar
        position="fixed"
        sx={{
          // PC表示のpermanentでAppBarよりもzIndexが大きかったので+1して上に上げる
          zIndex: (theme) => (isPc ? theme.zIndex.drawer + 1 : undefined)
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>{getTitle()}</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 'auto', marginLeft: 'auto'  }}>
                {/* {articleCount}<b>/5</b> */}

          {user ? (
                <>
                <Tooltip title={user.email} arrow>
                  <IconButton color="inherit">
                    <PersonIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Logout" arrow>
                  <IconButton color="inherit" onClick={handleLogout}>
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
                </>
  ) : (
                <>
                <Tooltip title="Login" arrow>
                  <IconButton color="inherit" onClick={() => navigate("/settings/login")}>
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Sign In" arrow>
                  <IconButton color="inherit" onClick={() => navigate("/settings/register")}>
                    <AppRegistrationIcon />
                  </IconButton>
                </Tooltip>
                </>
  )}
                  {/* <LanguageSwitcher /> */}
      </Box>
        </Toolbar>
      </AppBar>
      {/* NavigationDrawer */}
      <NavigationDrawer
        isOpen={isOpen}
        variant={isPc ? "permanent" : "temporary"}
        PaddingComponent={Toolbar}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {AppNavigationList}
      </NavigationDrawer>
      {/* 右側レイアウト */}
      <Box sx={{ flex: "1 1 0", display: "flex", flexDirection: "column" }}>
        <Toolbar />
        <Box sx={{ flex: "1 1 auto", padding: 5, overflow: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
           {/* Footer */}
      <Box
        component="footer"
        sx={{
          mt: "auto",
          py: 2,
          backgroundColor: "#f0f0f0",
          textAlign: "center",
        }}
      >
        {/* <Typography variant="body2" color="text.secondary">
          
          <Link href="/rule/privacy-policy" color="inherit" sx={{ mr: 1 }}>
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link href="/rule/term-of-service" color="inherit" sx={{ ml: 1 }}>
            Terms of Use
          </Link>
        </Typography> */}
      </Box>
    </Box>
    
  );
};
