import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Article as ArticleIcon,
  TrendingUp as TrendingIcon,
  Build as BuildIcon,
  TextFields as TextIcon,
  Image as ImageIcon,
  Security as SecurityIcon,
  Policy as PolicyIcon,
  Gavel as GavelIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { NavigationItem, NavigationSingleItem, NavigationGroupItem } from "../NavigationList";

const createNavigationSingleItem = (item: NavigationSingleItem) => item;
const createNavigationGroupItem = (item: NavigationGroupItem) => item;

export const NAVIGATION_ITEMS: NavigationItem[] = [
  // createNavigationSingleItem({
  //   icon: HomeIcon,
  //   title: 'HOME',
  //   href: "/",
  // }),

  createNavigationSingleItem({
    icon: BuildIcon, // RAG Suiteに合わせて設定
    title: 'AI Line Bot',
    href: "/linebots",
  }),
  createNavigationSingleItem({
    icon: ArticleIcon, // Companyのアイコン
    title: 'プロンプト一覧',
    href: "/prompts",
  }),

  createNavigationGroupItem({
    icon: SettingsIcon, // Settingに合わせて設定
    title: 'Setting',
    subs: [
      createNavigationSingleItem({
        // icon: SecurityIcon, // パスワード設定のアイコン
        title: 'パスワード設定',
        href: "/auth/forgot-password",
      }),
      createNavigationSingleItem({
        // icon: PolicyIcon, // プライバシーポリシーのアイコン
        title: 'プライバシーポリシー',
        href: "/rule/privacy-policy",
      }),
      createNavigationSingleItem({
        // icon: GavelIcon, // 利用規約のアイコン
        title: '利用規約',
        href: "/rule/term-of-service",
      }),
    ],
  }),
  createNavigationSingleItem({
    icon: ArticleIcon, // Companyのアイコン
    title: 'Company',
    href: "http://zero-one.llc/",
  }),
];
