import { FC, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import CryptoJS from "crypto-js";

type Form = {
  name: string;
  content: string;
  // tags: string;
  // category: string;
  type: string;
  isOpen: boolean;
};

// 暗号化キー（環境変数から取得）
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "default-encryption-key";

// 暗号化関数
// const encrypt = (text: string): string => {
//   return CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
// };

export const RagNewPage: FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { userId } = useAppContext(); // 現在のログインユーザーID
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const submit = async (data: Form) => {
    if (isSubmitting) return; // 二重送信防止

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      // データを暗号化
      const encryptedContent = data.content;
      // const encryptedTags = data.tags;
      console.log("userId",userId);
      // Firebaseに新しいRAGを登録
      const ragCollectionRef = collection(db, "rags");
      await addDoc(ragCollectionRef, {
        name: data.name,
        content: encryptedContent, // 暗号化されたコンテンツ
        // tags: encryptedTags, // 暗号化されたタグ
        // category: data.category,
        type: data.type, // RAGの種類
        isOpen: data.isOpen, // 公開設定
        userId: userId, // 登録者のユーザーID
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: 1, // 1 = open
      });
      navigate("/rags"); // 登録後に一覧ページへ移動
    } catch (error) {
      console.error("RAGの登録に失敗しました", error);
      setErrorMessage("RAGの登録中にエラーが発生しました。再試行してください。");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(submit)}
      sx={{
        p: 5,
        mt: 4,
        mx: "auto",
        maxWidth: "600px", // フォームの横幅を拡大
        border: "1px dashed grey",
        borderRadius: 2,
        backgroundColor: "#f7f7f7",
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h5" textAlign="center">
          新しいRAGを登録
        </Typography>

        {/* RAG名 */}
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "RAG名は必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="RAG名"
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />

        {/* コンテンツ */}
        <Controller
          name="content"
          control={control}
          defaultValue=""
          rules={{
            required: "コンテンツは必須です",
            maxLength: { value: 2000, message: "コンテンツは2000文字以内です" },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="コンテンツ"
              fullWidth
              multiline
              rows={8}
              error={!!errors.content}
              helperText={errors.content?.message}
            />
          )}
        />

        {/* タグ */}
        {/* <Controller
          name="tags"
          control={control}
          defaultValue=""
          // rules={{ required: "タグは必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="タグ (カンマ区切り)"
              fullWidth
              error={!!errors.tags}
              helperText={errors.tags?.message}
            />
          )}
        /> */}

        {/* カテゴリー */}
        {/* <Controller
          name="category"
          control={control}
          defaultValue=""
          // rules={{ required: "カテゴリーは必須です" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="カテゴリー"
              fullWidth
              error={!!errors.category}
              helperText={errors.category?.message}
            />
          )}
        /> */}

        {/* RAGの種類 */}
        <Controller
          name="type"
          control={control}
          defaultValue="product" // デフォルトを "product" に設定
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="RAGの種類"
              fullWidth
              SelectProps={{ native: true }}
            >
              <option value="product">製品情報</option>
              <option value="faq">FAQ</option>
              <option value="guide">ガイド</option>
            </TextField>
          )}
        />

        {/* 公開設定 */}
        <Controller
          name="isOpen"
          control={control}
          defaultValue={false} // デフォルトで非公開
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} />}
              label="公開設定（ON: 一般公開）"
            />
          )}
        />

        {/* エラーメッセージ */}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        {/* 登録ボタン */}
        <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : "登録する"}
        </Button>
      </Stack>
    </Box>
  );
};
